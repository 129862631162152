import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { visuallyHidden } from "@mui/utils";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Stack } from "@mui/material";
import { FormGroup } from "@mui/material";
import { revisoftLogo, welcomeImage } from "../../../assets/image";
import AddPatrimonialeConto from "../Add/StatoPatrimoniale/AddConto/AddConto";
import AddPatrimonialeSottoConto from "../Add/StatoPatrimoniale/AddSottoConto/AddSottoConto";
import AddEconomicoConto from "../Add/ContoEconomico/AddConto/AddConto";
import AddEconomicoSottoConto from "../Add/ContoEconomico/AddSottoConto/AddSottoConto";
import { DeleteDialog } from "../../../components/DeleteDialog";
import { getDataFromStorage } from "storage/StorageData";
import defaultString from "../../../constants/defaultString.json";
import { API } from "service/AxiosService";
import apiURL from "../../../apiURL/apiURL";
import apiStatusCode from "../../../constants/apiStatusCode";
import { Collapse } from "@mui/material";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import { Padding } from "@mui/icons-material";
import { RiFileExcel2Fill } from "react-icons/ri";

function EnhancedTableHead(props) {
  const { t } = useTranslation();

  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const headCells = [
    {
      id: "codice",
      numeric: false,
      disablePadding: true,
      label: t("codice"),
    },
    {
      id: "nome2",
      numeric: false,
      disablePadding: false,
      label: t("nome"),
    },
    {
      id: "modifica",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            // sx={{padding:"16px 16px 16px 0px"}}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableHead2(props) {
  const { t } = useTranslation();

  const {
    onSelectAllClick2,
    order2,
    orderBy2,
    numSelected2,
    rowCount2,
    onRequestSort2,
  } = props;
  const createSortHandler2 = (property) => (event) => {
    // onRequestSort2(event, property);
  };
  const headCells2 = [
    {
      id: "codice2",
      numeric: false,
      disablePadding: true,
      label: t("codice"),
    },
    {
      id: "nome2",
      numeric: true,
      disablePadding: false,
      label: t("nome"),
    },
    {
      id: "modifica",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected2 > 0 && numSelected2 < rowCount2}
            checked={rowCount2 > 0 && numSelected2 === rowCount2}
            onChange={onSelectAllClick2}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          /> */}
        </TableCell>
        {headCells2.map((headCell2) => (
          <TableCell
            key={headCell2.id}
            align={headCell2.numeric ? "left" : "left"}
            padding={headCell2.disablePadding ? "none" : "normal"}
            sortDirection={orderBy2 === headCell2.id ? order2 : false}
          >
            {headCell2.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  // onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  // order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  // orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

EnhancedTableHead2.propTypes = {
  numSelected2: PropTypes.number.isRequired,
  // onRequestSort2: PropTypes.func.isRequired,
  onSelectAllClick2: PropTypes.func.isRequired,
  // order2: PropTypes.oneOf(["asc", "desc"]).isRequired,
  // orderBy2: PropTypes.string.isRequired,
  rowCount2: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    searchAccountContent,
    handleRemoveSearchTextForStato,
    setRows,
    originalData,
  } = props;
  const { i18n, t } = useTranslation();

  return (
    <Box>
      <Typography
        variant="h4"
        sx={{
          pt: { sm: 1 },
          pl: { sm: 2 },
          pr: { xs: 2, sm: 2 },
        }}
      >
        {t("statoPatrimoniale")}
      </Typography>
      <Toolbar
        sx={{
          pt: { sm: 1 },
          pb: { sm: 1 },
          pl: { sm: 2 },
          pr: { xs: 2, sm: 2 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
          display: "flex",
        }}
      >
        {numSelected == 1 ? (
          <Typography
            sx={{ flex: "1 1" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} Selezionato
          </Typography>
        ) : numSelected > 1 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} Selezionati
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <TextField
              size="small"
              label={t("cerca")}
              placeholder={t("nome") + ", " + t("codice")}
              variant="outlined"
              onChange={props.handleAccountSearch}
              sx={{ width: "15pc", marginRight: "16px" }}
              value={searchAccountContent}
            />
            {/*<TextField*/}
            {/*    label={t("sottoConto")}*/}
            {/*    placeholder={t("nome") + ", " + t("codice")}*/}
            {/*    variant="outlined"*/}
            {/*    onChange={props.handleSubAccountSearch}*/}
            {/*    sx={{width: "15pc"}}*/}
            {/*/>*/}
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton
              onClick={() => {
                props.onDelete();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip
            sx={{
              "&:hover": {
                backgroundColor: "#ffffff",
                boxShadow: "none",
              },
            }}
          >
            <Box sx={{ display: "flex" }}>
              {/*<Button*/}
              {/*  variant="contained"*/}
              {/*  color="error"*/}
              {/*  size="large"*/}
              {/*  onClick={() => {*/}
              {/*    props.onDelete();*/}
              {/*  }}*/}
              {/*  startIcon={<DeleteIcon />}*/}
              {/*>*/}
              {/*  {t("elimina")}*/}
              {/*</Button>*/}

              <Button
                sx={{
                  marginLeft: "16px",
                }}
                onClick={() => {
                  props.onPatrimonialeContoOpen();
                  // handleRemoveSearchTextForStato();
                  // setRows(originalData);
                }}
                variant="contained"
                size="small"
                startIcon={<AddIcon />}
              >
                {t("creaConto")}
              </Button>

              <Button
                sx={{
                  marginLeft: "16px",
                }}
                onClick={() => {
                  props.onPatrimonialeSottoContoOpen();
                  // handleRemoveSearchTextForStato();
                  // setRows(originalData);
                }}
                variant="contained"
                size="small"
                startIcon={<AddIcon />}
              >
                {t("creaSttoconto")}
              </Button>
            </Box>
          </Tooltip>
        )}
      </Toolbar>
    </Box>
  );
}

function EnhancedTableToolbar2(props) {
  const {
    numSelected2,
    searchEconomicoAccountContent,
    handleRemoveSearchTextForEconomico,
    setRowsEconomico,
    originalEconomicoData,
  } = props;
  const { i18n, t } = useTranslation();

  return (
    <Box>
      <Typography
        variant="h4"
        sx={{
          pt: { sm: 1 },
          pl: { sm: 2 },
          pr: { xs: 2, sm: 2 },
        }}
      >
        {t("contoEconomico")}
      </Typography>
      <Toolbar
        sx={{
          pt: { sm: 1 },
          pb: { sm: 1 },
          pl: { sm: 2 },
          pr: { xs: 2, sm: 2 },
          ...(numSelected2 > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
          display: "flex",
        }}
      >
        {numSelected2 == 1 ? (
          <Typography
            sx={{ flex: "1 1" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected2} Selezionato
          </Typography>
        ) : numSelected2 > 1 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected2} Selezionati
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            <TextField
              size="small"
              id="outlined-basic"
              label={t("cerca")}
              placeholder={t("nome") + ", " + t("codice")}
              variant="outlined"
              onChange={props.handleAccountSearch}
              value={searchEconomicoAccountContent}
              sx={{ width: "15pc", marginRight: "16px" }}
            />
            {/*<TextField*/}
            {/*    id="outlined-basic"*/}
            {/*    label={t("sottoConto")}*/}
            {/*    placeholder={t("nome") + ", " + t("codice")}*/}
            {/*    variant="outlined"*/}
            {/*    onChange={props.handleSubAccountSearch}*/}
            {/*    sx={{width: "15pc"}}*/}
            {/*/>*/}
          </Typography>
        )}

        {numSelected2 > 0 ? (
          <Tooltip title="Delete">
            <IconButton
              onClick={() => {
                props.onDelete();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip
            sx={{
              "&:hover": {
                backgroundColor: "#ffffff",
                boxShadow: "none",
              },
            }}
          >
            <Box sx={{ display: "flex" }}>
              {/*<Button*/}
              {/*  onClick={() => {*/}
              {/*    props.onDelete();*/}
              {/*  }}*/}
              {/*  variant="contained"*/}
              {/*  color="error"*/}
              {/*  size="large"*/}
              {/*  startIcon={<DeleteIcon />}*/}
              {/*>*/}
              {/*  {t("elimina")}*/}
              {/*</Button>*/}

              <Button
                sx={{
                  marginLeft: "16px",
                }}
                onClick={() => {
                  props.onEconomicoContoOpen();
                  // handleRemoveSearchTextForEconomico();
                  // setRowsEconomico(originalEconomicoData);
                }}
                variant="contained"
                size="small"
                startIcon={<AddIcon />}
              >
                {t("creaConto")}
              </Button>

              <Button
                sx={{
                  marginLeft: "16px",
                }}
                onClick={() => {
                  props.onEconomicoSottoContoOpen();
                  // handleRemoveSearchTextForEconomico();
                  // setRowsEconomico(originalEconomicoData);
                }}
                variant="contained"
                size="small"
                startIcon={<AddIcon />}
              >
                {t("creaSttoconto")}
              </Button>
            </Box>
          </Tooltip>
        )}
      </Toolbar>
    </Box>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleAccountSearch: PropTypes.func,
  handleSubAccountSearch: PropTypes.func,
  accountSearchContent: PropTypes.string,
  subAccountSearchContent: PropTypes.string,
};

EnhancedTableToolbar2.propTypes = {
  numSelected2: PropTypes.number.isRequired,
  handleAccountSearch: PropTypes.func,
  handleSubAccountSearch: PropTypes.func,
  accountSearchContent: PropTypes.string,
  subAccountSearchContent: PropTypes.string,
};

export default function EnhancedTable() {
  const [value, setValue] = React.useState("1");

  const handleTebsChange = (event, newValue) => {
    // console.log(newValue)
    if (newValue == 1) {
      handleRemoveSearchTextForStato();
      setRows(originalData);
    } else {
      handleRemoveSearchTextForEconomico();
      setRowsEconomico(originalEconomicoData);
    }
    setValue(newValue);
  };

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("codice");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [openPetrimonialeConto, setopenPetrimonialeConto] = useState(false);
  const [openPetrimonialeSottoConto, setopenPetrimonialeSottoConto] =
    useState(false);
  const [openEconomicoConto, setopenEconomicoConto] = useState(false);
  const [openEconomicoSottoConto, setopenEconomicoSottoConto] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [rows, setRows] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [limit, setLimit] = useState(2);
  const [searchAccountContent, setSearchAccountContent] = useState("");
  const [searchSubAccountContent, setSearchSubAccountContent] = useState("");
  const [accountObj, setAccountObj] = useState(null);
  const [isEditAccount, setEditAccount] = useState(false);
  const [subAccountObj, setSubAccountObj] = useState(null);
  const [isEditSubAccount, setEditSubAccount] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsEconomico, setRowsEconomico] = useState([]);
  const [originalEconomicoData, setOriginalEconomicoData] = useState([]);
  const [selectedEconomico, setSelectedEconomico] = React.useState([]);
  const [pageEconomico, setPageEconomico] = React.useState(0);
  const [rowsPerPageEconomico, setRowsPerPageEconomico] = React.useState(25);
  const [limitEconomico, setLimitEconomico] = useState(2);
  const [totalRecordsEconomico, setTotalRecordsEconomico] = useState(0);
  const [searchEconomicoAccountContent, setSearchEconomicoAccountContent] =
    useState("");
  const [
    searchSubEconomicoAccountContent,
    setSearchSubEconomicoAccountContent,
  ] = useState("");
  const [isEditEcoAccount, setEditEcoAccount] = useState(false);
  const [ecoAccountObj, setEcoAccountObj] = useState(null);
  const [subEcoAccountObj, setEcoSubAccountObj] = useState(null);
  const [isEditEcoSubAccount, setEditEcoSubAccount] = useState(false);
  const [severity, setSeverity] = useState("success");

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const getAllStatoAccounts = useCallback(
    async (page) => {
      const accountingSubject = await getDataFromStorage(
        defaultString.accountingSubject
      );
      API.get(
        apiURL.service_get_stato_accounts +
          `?page=${page}&type=${1}&id=${accountingSubject._id}`
      )
        .then((res) => {
          setIsLoading(false);
          if (res.status === apiStatusCode.SUCCESS) {
            setRows(res.data.data.accounts);
            setOriginalData(res.data.data.accounts);
            setTotalRecords(res.data.data.accounts.length);
          } else {
            setOpen(true);
            setErrorMsg(t("common.internalServerError"));
            setSeverity("error");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setOpen(true);
          setSeverity("error");
          if (error.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.NOTFOUND) {
              setOpen(false);
            } else if (
              error.response.data.status === apiStatusCode.UNAUTHORIZED
            ) {
              setOpen(true);
              setErrorMsg(t("common.unAuthorized"));
            }
          }
          setRows([]);
          setOriginalData([]);
          setTotalRecords(0);
        });
    },
    [limit]
  );

  const getAllContoAccounts = useCallback(
    async (page) => {
      const accountingSubject = await getDataFromStorage(
        defaultString.accountingSubject
      );
      API.get(
        apiURL.service_get_stato_accounts +
          `?page=${page}&type=${2}&id=${accountingSubject._id}`
      )
        .then((res) => {
          setIsLoading(false);
          if (res.status === apiStatusCode.SUCCESS) {
            setRowsEconomico(res.data.data.accounts);
            setOriginalEconomicoData(res.data.data.accounts);
            setTotalRecordsEconomico(res.data.data.accounts.length);
          } else {
            setOpen(true);
            setSeverity("error");
            setErrorMsg(t("common.internalServerError"));
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setOpen(true);
          setSeverity("error");
          if (error.code === defaultString.network_error)
            setErrorMsg(t("common.networkError"));
          else if (error.code === defaultString.bad_request) {
            if (error.response.data.status === apiStatusCode.NOTFOUND) {
              setOpen(false);
            } else if (
              error.response.data.status === apiStatusCode.UNAUTHORIZED
            ) {
              setOpen(true);
              setErrorMsg(t("common.unAuthorized"));
            }
          }
          setRowsEconomico([]);
          setOriginalEconomicoData([]);
          setTotalRecordsEconomico(0);
        });
    },
    [limitEconomico]
  );

  const fnDeleteAccount = async (accountIds) => {
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));

    const idsToBeDeleted = JSON.stringify({
      accountIds: accountIds,
      accountingSubjectId: accountingSubject?._id,
    });

    await axios
      .post(apiURL.service_delete_account, idsToBeDeleted, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setErrorMsg(t("common.deleteSuccess"));
          setSeverity("success");

          await getAllStatoAccounts(page + 1);
          await getAllContoAccounts(pageEconomico + 1);

          const filteredArr = rows.filter(
            (item) => !selected.includes(item._id)
          );
          setRows(filteredArr);
          const filteredOriginalArr = originalData.filter(
            (item) => !selected.includes(item._id)
          );
          setOriginalData(filteredOriginalArr);
          setSelected([]);
          setSelected2([]);
        } else {
          setOpen(true);
          setSeverity("error");
          setErrorMsg(t("common.internalServerError"));
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
            setErrorMsg(t("common.unAuthorized"));
          } else {
            setErrorMsg(t("common.badRequest"));
          }
        }
      });
  };

  const fnDeleteSubAccount = async (listOfSubAccount) => {
    const accountingSubject = await getDataFromStorage(
      defaultString.accountingSubject
    );
    const token = JSON.parse(localStorage.getItem(defaultString.jsonWebToken));

    const idsToBeDeleted = JSON.stringify({
      accountIds: listOfSubAccount,
      accountingSubjectId: accountingSubject?._id,
    });

    await axios
      .post(apiURL.service_delete_subaccount, idsToBeDeleted, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.status === apiStatusCode.DELETED) {
          setOpen(true);
          setErrorMsg(t("common.deleteSuccess"));
          setSeverity("success");
          await getAllStatoAccounts(page + 1);
          await getAllContoAccounts(pageEconomico + 1);

          // const filteredArr = rows.filter(item => !selected.includes(item._id));
          // setRows(filteredArr);
          // const filteredOriginalArr = originalData.filter(item => !selected.includes(item._id));
          // setOriginalData(filteredOriginalArr);
          setSelected([]);
          setSelected2([]);
        } else {
          setOpen(true);
          setSeverity("error");
          setErrorMsg(t("common.internalServerError"));
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        setOpen(true);
        setSeverity("error");
        if (error.code === defaultString.network_error)
          setErrorMsg(t("common.networkError"));
        else if (error.code === defaultString.bad_request) {
          if (error.response.data.status === apiStatusCode.UNAUTHORIZED) {
            setErrorMsg(t("common.unAuthorized"));
          } else {
            setErrorMsg(t("common.badRequest"));
          }
        }
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getAllStatoAccounts(page + 1);
    getAllContoAccounts(pageEconomico + 1);
  }, []);
  //
  // useEffect(() => {
  //   setIsLoading(true);
  //   getAllContoAccounts(pageEconomico + 1);
  // }, [getAllContoAccounts, pageEconomico]);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    // await getAllStatoAccounts(newPage + 1);
    // setSelected([]);
  };

  const isSelected = (nome) => selected.indexOf(nome) !== -1;

  // const2
  const [selected2, setSelected2] = React.useState([]);

  const handleSelectAllClick2 = (event) => {
    // if (event.target.checked) {
    //   const newSelected2 = rows2.map((n) => n.id2);
    //   setSelected2(newSelected2);
    //   return;
    // }
    // setSelected2([]);
  };

  const handleClick2 = (event, id2) => {
    const selectedIndex = selected2.indexOf(id2);
    let newSelected2 = [];

    if (selectedIndex === -1) {
      newSelected2 = newSelected2.concat(selected2, id2);
    } else if (selectedIndex === 0) {
      newSelected2 = newSelected2.concat(selected2.slice(1));
    } else if (selectedIndex === selected2.length - 1) {
      newSelected2 = newSelected2.concat(selected2.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected2 = newSelected2.concat(
        selected2.slice(0, selectedIndex),
        selected2.slice(selectedIndex + 1)
      );
    }

    setSelected2(newSelected2);
  };

  const handleChangePage2 = async (event, newPage) => {
    setPageEconomico(newPage);
    // await getAllContoAccounts(newPage + 1);
    // setSelected2([]);
  };

  const isSelected2 = (id2) => selected2.indexOf(id2) !== -1;
  const navigateToUpdate = (data) => {
    console.log("account: ", data);
    if (data?.isBlocked) {
      setOpen(true);
      setErrorMsg(t("account.notEditable"));
    } else {
      setAccountObj(data);
      setEditAccount(true);
      setopenPetrimonialeConto(true);
    }
  };

  const navigateToUpdateSubAccount = (data) => {
    console.log("DATA = ", data);
    if (data?.isBlocked) {
      setOpen(true);
      setErrorMsg(t("account.notEditable"));
    } else {
      setSubAccountObj(data);
      setEditSubAccount(true);
      setopenPetrimonialeSottoConto(true);
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setopenPetrimonialeConto(false);
  };

  const navigateToUpdateEcoAccount = (data) => {
    if (data?.isBlocked) {
      setOpen(true);
      setErrorMsg(t("account.notEditable"));
    } else {
      setEditEcoAccount(true);
      setopenEconomicoConto(true);
      setEcoAccountObj(data);
    }
  };

  const navigateToUpdateEcoSubAccount = (data) => {
    if (data?.isBlocked) {
      setOpen(true);
      setErrorMsg(t("account.notEditable"));
    } else {
      console.log("40 min bread", JSON.stringify(data));
      setEditEcoSubAccount(true);
      setopenEconomicoSottoConto(true);
      setEcoSubAccountObj(data);
    }
  };

  const { t } = useTranslation();

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const confirmDelete = async () => {
    console.log("idsToBeDeleted", JSON.stringify(selected2));

    const listOfAccountIdForDelete = [];
    const listOfSubAccountIdForDelete = [];
    selected?.map((mId) => {
      const accountObj = originalData.filter((obj) => obj._id === mId);
      accountObj?.forEach((item) => {
        if (item?.isSubAccount) {
          listOfSubAccountIdForDelete.push(mId);
        } else {
          listOfAccountIdForDelete.push(mId);
        }
      });
      // if (accountObj?.isSubAccount) {
      //   listOfSubAccountIdForDelete.push(mId);
      // } else {
      //   listOfAccountIdForDelete.push(mId);
      // }
    });

    selected2?.map((mId) => {
      const accountObj = originalEconomicoData.filter((obj) => obj._id === mId);
      accountObj?.forEach((item) => {
        if (item?.isSubAccount) {
          listOfSubAccountIdForDelete.push(mId);
        } else {
          listOfAccountIdForDelete.push(mId);
        }
      });
      // if (accountObj[0].isSubAccount) {
      //   listOfSubAccountIdForDelete.push(accountObj);
      // } else {
      //   listOfAccountIdForDelete.push(mId);
      // }
    });

    console.log("account id ", JSON.stringify(listOfAccountIdForDelete));
    console.log("sub account id ", JSON.stringify(listOfSubAccountIdForDelete));

    if (listOfAccountIdForDelete.length > 0) {
      await fnDeleteAccount(listOfAccountIdForDelete);
    }

    if (listOfSubAccountIdForDelete.length > 0) {
      await fnDeleteSubAccount(listOfSubAccountIdForDelete);
    }
    setOpenDelete(!openDelete);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeEcoRowsPerPage = (event) => {
    setRowsPerPageEconomico(parseInt(event.target.value, 10));
    setRowsPerPage(0);
  };

  function customSort(a, b) {
    const keyA = !a.isSubAccount
      ? `${a.type}.${a.code}`
      : `${a.type}.${a?.accountId?.code}.${a.code}`;
    const keyB = !b.isSubAccount
      ? `${b.type}.${b.code}`
      : `${b.type}.${b?.accountId?.code}.${b.code}`;

    // Split the keys into their components
    const partsA = keyA.split(".").map((part) => parseInt(part, 10));
    const partsB = keyB.split(".").map((part) => parseInt(part, 10));

    // Compare each component in order
    for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
      if (partsA[i] !== partsB[i]) {
        return partsA[i] - partsB[i];
      }
    }

    // If all components are equal, sort by the length of the keys (shorter keys first)
    return partsA.length - partsB.length;
  }
  rows.sort(customSort);
  rowsEconomico.sort(customSort);

  const getPianoDeiContiExcel = async () => {
    try {
      setIsLoading(true);
      const accountingSubject = await getDataFromStorage(
        defaultString.accountingSubject
      );
      const token = JSON.parse(
        localStorage.getItem(defaultString.jsonWebToken)
      );

      const response = await axios.get(
        `${apiURL.service_get_excel_piano_dei_conti}?id=${accountingSubject._id}`,
        {
          headers: {
            Authorization: token,
          },
          responseType: "blob",
        }
      );

      // Crea un URL oggetto per il download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "piano_dei_conti.xlsx");

      // Aggiungi il link al body
      document.body.appendChild(link);

      // Avvia il download
      link.click();

      // Pulisci rimuovendo il link
      link.parentNode.removeChild(link);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setOpen(true);
      setSeverity("error");

      if (error.code === defaultString.network_error) {
        setErrorMsg(t("common.networkError"));
      } else if (error.code === defaultString.bad_request) {
        if (error.response?.data?.status === apiStatusCode.UNAUTHORIZED) {
          setErrorMsg(t("common.unAuthorized"));
        } else {
          setErrorMsg(t("common.badRequest"));
        }
      } else {
        setErrorMsg(t("common.internalServerError"));
      }
    }
  };
  // Sort the visibleRows array using the custom comparison function

  const visibleRows = React.useMemo(
    () => rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, rows]
  );

  const visibleEcoRows = React.useMemo(
    () =>
      rowsEconomico.slice(
        pageEconomico * rowsPerPageEconomico,
        pageEconomico * rowsPerPageEconomico + rowsPerPageEconomico
      ),
    [pageEconomico, rowsPerPageEconomico, rowsEconomico]
  );

  function handleRemoveSearchTextForStato() {
    setSearchAccountContent("");
  }

  function handleRemoveSearchTextForEconomico() {
    setSearchEconomicoAccountContent("");
  }

  const AccountsList = (
    <Box sx={{ width: "100%", marginTop: "48px" }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h3">{t("pianoDeiConti")}</Typography>
        <Button
          size="small"
          variant="contained"
          startIcon={<RiFileExcel2Fill />}
          onClick={getPianoDeiContiExcel}
        >
          {t("Excel")}
        </Button>
      </Box>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Box sx={{ width: "100%" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleTebsChange}
                aria-label="lab API tabs example"
              >
                <Tab label={t("statoPatrimoniale")} value="1" />
                <Tab label={t("contoEconomico")} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: "16px !important" }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                onPatrimonialeContoOpen={() => {
                  setopenPetrimonialeConto(!openPetrimonialeConto);
                }}
                onPatrimonialeSottoContoOpen={() => {
                  setopenPetrimonialeSottoConto(!openPetrimonialeSottoConto);
                }}
                onDelete={() => {
                  setOpenDelete(!openDelete);
                }}
                handleAccountSearch={(event) => {
                  const search = event.target.value;
                  setSearchAccountContent(search);
                  if (search.trim().length > 0) {
                    const filteredData = originalData.filter((item) => {
                      const isSubAccount = item?.isSubAccount ? true : false;
                      let codice = !isSubAccount
                        ? `${item.type}.${item.code}`
                        : `${item.type}.${item?.accountId?.code}.${item.code}`;
                      return (
                        item.name
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        codice.includes(search.toLowerCase())
                      );
                    });
                    setRows(filteredData);
                    setPage(0);
                  } else {
                    setRows(originalData);
                    setPage(0);
                  }
                }}
                searchAccountContent={searchAccountContent}
                setSearchAccountContent={setSearchAccountContent}
                handleRemoveSearchTextForStato={handleRemoveSearchTextForStato}
                setPage={setPage}
                setRows={setRows}
                originalData={originalData}
              />
              <TableContainer
                sx={{
                  maxHeight: "58vh",
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#4d96ff",
                    borderRadius: "10px",
                  },
                }}
              >
                <Table
                  stickyHeader
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size="small"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row._id);
                      const labelId = `enhanced-table-checkbox-${row._id}`;
                      const isSubAccount = row?.isSubAccount ? true : false;
                      const isModify = row?.isAllowEdit ? true : false;
                      return (
                        <React.Fragment key={row._id}>
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row._id}
                            selected={isItemSelected}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell
                              padding="checkbox"
                              onClick={(event) => handleClick(event, row._id)}
                            >
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {!isSubAccount ? (
                                <b>
                                  {row.type}.{row.code}
                                </b>
                              ) : (
                                `${row.type}.${row?.accountId?.code}.${row.code}`
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {!isSubAccount ? (
                                <b>
                                  <span>{row.name}</span>
                                </b>
                              ) : (
                                <span>{row.name}</span>
                              )}
                            </TableCell>
                            <TableCell>
                              {isSubAccount && (
                                <Button
                                  size="small"
                                  variant="contained"
                                  type="button"
                                  disabled={!isModify && isSubAccount}
                                  onClick={() => {
                                    if (!isSubAccount) {
                                      navigateToUpdate(row);
                                      // handleRemoveSearchTextForStato();
                                      // setRows(originalData);
                                    } else {
                                      navigateToUpdateSubAccount(row);
                                      // handleRemoveSearchTextForStato();
                                      // setRows(originalData);
                                    }
                                  }}
                                >
                                  {t("edit")}
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                          {/*{row?.subaccounts?.map((subAccount, subIndex) => {*/}
                          {/*  const isSubItemSelected = isSelected(*/}
                          {/*      subAccount._id*/}
                          {/*  );*/}
                          {/*  const subLabelId = `enhanced-table-checkbox-${subAccount._id}`;*/}
                          {/*  return (*/}
                          {/*      <TableRow*/}
                          {/*          hover*/}
                          {/*          role="checkbox"*/}
                          {/*          aria-checked={isSubItemSelected}*/}
                          {/*          tabIndex={-1}*/}
                          {/*          key={subAccount._id}*/}
                          {/*          selected={isSubItemSelected}*/}
                          {/*          sx={{cursor: "pointer"}}*/}
                          {/*      >*/}
                          {/*        /!*<TableCell colSpan={5}>*!/*/}
                          {/*        /!*<Collapse in={openSubAccount[row._id]} unmountOnExit >*!/*/}
                          {/*        /!*<Box margin={1}>*!/*/}
                          {/*        <TableCell*/}
                          {/*            padding="checkbox"*/}
                          {/*            onClick={(event) =>*/}
                          {/*                handleClick(event, subAccount._id)*/}
                          {/*            }*/}
                          {/*        >*/}
                          {/*          <Checkbox*/}
                          {/*              color="primary"*/}
                          {/*              checked={isSubItemSelected}*/}
                          {/*              inputProps={{*/}
                          {/*                "aria-labelledby": subLabelId,*/}
                          {/*              }}*/}
                          {/*          />*/}
                          {/*        </TableCell>*/}
                          {/*        <TableCell*/}
                          {/*            component="th"*/}
                          {/*            id={subLabelId}*/}
                          {/*            scope="row"*/}
                          {/*            padding="none"*/}
                          {/*        >*/}
                          {/*          {`${subAccount.type}.${row.code}.${subAccount.code}`}*/}
                          {/*        </TableCell>*/}
                          {/*        <TableCell*/}
                          {/*            align="left"*/}
                          {/*            //  sx={{width:"200px"}}*/}
                          {/*        >*/}
                          {/*          {subAccount.name}*/}
                          {/*        </TableCell>*/}
                          {/*        <TableCell>*/}
                          {/*          <Button*/}
                          {/*              variant="contained"*/}
                          {/*              type="button"*/}
                          {/*              onClick={() => {*/}
                          {/*                // console.log(JSON.stringify("TTT 2== "+subAccount))*/}
                          {/*                navigateToUpdateSubAccount(subAccount);*/}
                          {/*              }}*/}
                          {/*          >*/}
                          {/*            {t("edit")}*/}
                          {/*          </Button>*/}
                          {/*        </TableCell>*/}
                          {/*        /!*</Box>*!/*/}
                          {/*        /!*</Collapse>*!/*/}
                          {/*        /!*</TableCell>*!/*/}
                          {/*      </TableRow>*/}
                          {/*  );*/}
                          {/*})}*/}
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {searchAccountContent.trim().length === 0 &&
              searchSubAccountContent.trim().length === 0 ? (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  component="div"
                  count={totalRecords}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  // labelDisplayedRows={() => ""}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              ) : null}
            </TabPanel>

            <TabPanel value="2" sx={{ padding: "16px !important" }}>
              <EnhancedTableToolbar2
                numSelected2={selected2.length}
                onEconomicoContoOpen={() => {
                  setopenEconomicoConto(!openEconomicoConto);
                }}
                onEconomicoSottoContoOpen={() => {
                  setopenEconomicoSottoConto(!openEconomicoSottoConto);
                }}
                onDelete={() => {
                  setOpenDelete(!openDelete);
                }}
                handleAccountSearch={(event) => {
                  const search = event.target.value;
                  setSearchEconomicoAccountContent(search);
                  if (search.trim().length > 0) {
                    const filteredData = originalEconomicoData.filter(
                      (item) => {
                        const isSubAccount = item?.isSubAccount ? true : false;
                        let codice = !isSubAccount
                          ? `${item.type}.${item.code}`
                          : `${item.type}.${item?.accountId?.code}.${item.code}`;
                        return (
                          item.name
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          codice.includes(search.toLowerCase())
                        );
                      }
                    );
                    setRowsEconomico(filteredData);
                    setPageEconomico(0);
                  } else {
                    setRowsEconomico(originalEconomicoData);
                    setPageEconomico(0);
                  }
                }}
                searchEconomicoAccountContent={searchEconomicoAccountContent}
                setSearchEconomicoAccountContent={
                  setSearchEconomicoAccountContent
                }
                handleRemoveSearchTextForEconomico={
                  handleRemoveSearchTextForEconomico
                }
                setPageEconomico={setPageEconomico}
                setRowsEconomico={setRowsEconomico}
                originalEconomicoData={originalEconomicoData}
                // handleSubAccountSearch={(event) => {
                //   const search = event.target.value;
                //   setSearchSubEconomicoAccountContent(search);
                //   if (search.trim().length > 0) {
                //     const filteredData = originalEconomicoData.filter(
                //         (account) =>
                //             account.subaccounts.some((subAccount) =>
                //                 subAccount.name
                //                     .toLowerCase()
                //                     .includes(search.toLowerCase())
                //             )
                //     );
                //     setRowsEconomico(filteredData);
                //   } else {
                //     setRowsEconomico(originalEconomicoData);
                //   }
                // }}
              />
              <TableContainer
                sx={{
                  maxHeight: "60vh",
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#4d96ff",
                    borderRadius: "10px",
                  },
                }}
              >
                <Table
                  stickyHeader
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size="small"
                >
                  <EnhancedTableHead2
                    numSelected2={selected2.length}
                    onSelectAllClick2={handleSelectAllClick2}
                    rowCount2={rows.length}
                  />
                  <TableBody>
                    {visibleEcoRows.map((row, index) => {
                      const isItemSelected2 = isSelected2(row._id);
                      const labelId = `enhanced-table-checkbox-${row._id}`;
                      const isSubAccount = row?.isSubAccount ? true : false;
                      const isModify = row?.isAllowEdit ? true : false;
                      return (
                        <React.Fragment key={row._id}>
                          <TableRow
                            hover
                            // onClick={(event) => handleClick2(event, row.id2)}
                            role="checkbox"
                            aria-checked={isItemSelected2}
                            tabIndex={-1}
                            key={row._id}
                            selected2={isItemSelected2}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell
                              padding="checkbox"
                              onClick={(event) => handleClick2(event, row._id)}
                            >
                              <Checkbox
                                color="primary"
                                checked={isItemSelected2}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {!isSubAccount ? (
                                <b>
                                  {row.type}.{row.code}
                                </b>
                              ) : (
                                `${row.type}.${row?.accountId?.code}.${row.code}`
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {!isSubAccount ? (
                                <b>
                                  <span>{row.name}</span>
                                </b>
                              ) : (
                                <span>{row.name}</span>
                              )}
                            </TableCell>
                            <TableCell>
                              {isSubAccount && (
                                <Button
                                  size="small"
                                  variant="contained"
                                  type="button"
                                  disabled={!isModify && isSubAccount}
                                  onClick={() => {
                                    if (!isSubAccount) {
                                      navigateToUpdateEcoAccount(row);
                                      // handleRemoveSearchTextForEconomico();
                                      // setRowsEconomico(originalEconomicoData);
                                    } else {
                                      navigateToUpdateEcoSubAccount(row);
                                      // handleRemoveSearchTextForEconomico();
                                      // setRowsEconomico(originalEconomicoData);
                                    }
                                  }}
                                >
                                  {t("edit")}
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>

                          {/*{row?.subaccounts?.map((subAccount, subIndex) => {*/}
                          {/*  const isSubItemSelected = isSelected(*/}
                          {/*      subAccount._id*/}
                          {/*  );*/}
                          {/*  const subLabelId = `enhanced-table-checkbox-${subAccount._id}`;*/}
                          {/*  return (*/}
                          {/*      <TableRow*/}
                          {/*          hover*/}
                          {/*          role="checkbox"*/}
                          {/*          aria-checked={isSubItemSelected}*/}
                          {/*          tabIndex={-1}*/}
                          {/*          key={subAccount._id}*/}
                          {/*          selected={isSubItemSelected}*/}
                          {/*          sx={{cursor: "pointer"}}*/}
                          {/*      >*/}
                          {/*        /!*<TableCell colSpan={5}>*!/*/}
                          {/*        /!*<Collapse in={openSubAccount[row._id]} unmountOnExit >*!/*/}
                          {/*        /!*<Box margin={1}>*!/*/}
                          {/*        <TableCell*/}
                          {/*            padding="checkbox"*/}
                          {/*            onClick={(event) =>*/}
                          {/*                handleClick(event, subAccount._id)*/}
                          {/*            }*/}
                          {/*        >*/}
                          {/*          <Checkbox*/}
                          {/*              color="primary"*/}
                          {/*              checked={isSubItemSelected}*/}
                          {/*              inputProps={{*/}
                          {/*                "aria-labelledby": subLabelId,*/}
                          {/*              }}*/}
                          {/*          />*/}
                          {/*        </TableCell>*/}
                          {/*        <TableCell*/}
                          {/*            component="th"*/}
                          {/*            id={subLabelId}*/}
                          {/*            scope="row"*/}
                          {/*            padding="none"*/}
                          {/*        >*/}
                          {/*          {`${subAccount.type}.${row.code}.${subAccount.code}`}*/}
                          {/*        </TableCell>*/}
                          {/*        <TableCell*/}
                          {/*            align="left"*/}
                          {/*            //  sx={{width:"200px"}}*/}
                          {/*        >*/}
                          {/*          {subAccount.name}*/}
                          {/*        </TableCell>*/}
                          {/*        <TableCell>*/}
                          {/*          <Button*/}
                          {/*              variant="contained"*/}
                          {/*              type="button"*/}
                          {/*              onClick={() => {*/}
                          {/*                navigateToUpdateEcoSubAccount(subAccount);*/}
                          {/*              }}*/}
                          {/*          >*/}
                          {/*            {t("edit")}*/}
                          {/*          </Button>*/}
                          {/*        </TableCell>*/}
                          {/*        /!*</Box>*!/*/}
                          {/*        /!*</Collapse>*!/*/}
                          {/*        /!*</TableCell>*!/*/}
                          {/*      </TableRow>*/}
                          {/*  );*/}
                          {/*})}*/}
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {searchEconomicoAccountContent.trim().length === 0 &&
              searchSubEconomicoAccountContent.trim().length === 0 ? (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  component="div"
                  count={totalRecordsEconomico}
                  rowsPerPage={rowsPerPageEconomico}
                  page={pageEconomico}
                  onPageChange={handleChangePage2}
                  // labelDisplayedRows={() => ""}
                  onRowsPerPageChange={handleChangeEcoRowsPerPage}
                />
              ) : null}
            </TabPanel>
          </TabContext>
        </Box>
      </Paper>

      <AddPatrimonialeConto
        open={openPetrimonialeConto}
        isEdit={isEditAccount}
        stateObj={accountObj}
        handleClose={(data) => {
          if (data?.reload) {
            setIsLoading(true);
            getAllStatoAccounts(page + 1);
            handleRemoveSearchTextForStato();
          }
          setEditAccount(false);
          setAccountObj(null);
          setopenPetrimonialeConto(!openPetrimonialeConto);
        }}
      />
      <AddPatrimonialeSottoConto
        open={openPetrimonialeSottoConto}
        isEdit={isEditSubAccount}
        stateObj={subAccountObj}
        handleClose={(data) => {
          if (data?.reload) {
            setIsLoading(true);
            getAllStatoAccounts(page + 1);
            handleRemoveSearchTextForStato();
          }
          setEditSubAccount(false);
          setSubAccountObj(null);
          setopenPetrimonialeSottoConto(!openPetrimonialeSottoConto);
        }}
      />
      <AddEconomicoConto
        open={openEconomicoConto}
        isEdit={isEditEcoAccount}
        stateObj={ecoAccountObj}
        handleClose={(data) => {
          if (data?.reload) {
            setIsLoading(true);
            getAllContoAccounts(pageEconomico + 1);
            handleRemoveSearchTextForEconomico();
          }
          setEditEcoAccount(false);
          setEcoAccountObj(null);
          setopenEconomicoConto(!openEconomicoConto);
        }}
      />
      <AddEconomicoSottoConto
        open={openEconomicoSottoConto}
        isEdit={isEditEcoSubAccount}
        stateObj={subEcoAccountObj}
        handleClose={(data) => {
          if (data?.reload) {
            setIsLoading(true);
            getAllContoAccounts(pageEconomico + 1);
            handleRemoveSearchTextForEconomico();
          }
          setEditEcoSubAccount(false);
          setEcoSubAccountObj(null);
          setopenEconomicoSottoConto(!openEconomicoSottoConto);
        }}
      />

      <DeleteDialog
        open={openDelete}
        handleClose={() => setOpenDelete(!openDelete)}
        onConfirm={confirmDelete}
      />
    </Box>
  );

  return (
    <>
      {isLoading ?  <LoadingSpinner /> : AccountsList}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        sx={{ width: "100%" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          sx={{ color: "#fff" }}
          severity={severity}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
