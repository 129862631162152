import React, { useContext, useEffect } from "react";
import { Global, css } from "@emotion/react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { revisoftLogo } from "../../assets/image";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import AddIcon from "@mui/icons-material/Add";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DescriptionIcon from "@mui/icons-material/Description";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PrintIcon from "@mui/icons-material/Print";
import ReceiptIcon from "@mui/icons-material/Receipt";

import Dashboard from "screens/dashboard/Dashboard";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Modal from "@mui/material/Modal";

import UserList from "screens/users/List/UserList";
import AccountSubjectsList from "screens/accountSubjects/List/AccountSubjectsList";
import PianoDeiCountiList from "screens/pianoDeiConti/List/PianoDeiContiList";
import { ClientiList } from "screens/Anagrafiche/clienti/List";
import { FornitoriList } from "screens/Anagrafiche/fornitori/List";
import EserciziList from "screens/esercizi/List/EserciziList";
import Mese from "screens/stampe/Mese/MeseList";
import Anno from "screens/stampe/Anno/List/AnnoList";
import TabelleIVAList from "screens/tabelleIVA/List/TabelleIVAList";
import FattureVenditaList from "screens/fatture/fattureVendita/List/FattureVenditaList";
import FattureReverseChargeList from "screens/fatture/fattureReverseCharge/List/FattureReverseChargeList";
import FattureAcquistoList from "screens/fatture/fattureAcquisto/List/FattureAcquistoList";
import PrimaNotaList from "screens/primaNota/List/PrimaNotaList";
import MastroContabile from "screens/stampe/MastroContabile/List/MastroContabile";
import AddFattureVendita from "screens/fatture/fattureVendita/Add/AddFattureVendita";
import AddFattureAcquisto from "screens/fatture/fattureAcquisto/Add/AddFattureAcquisto";
import AddFattureReverseCharge from "screens/fatture/fattureReverseCharge/Add/AddFattureReverseCharge";
import RegistraPrimaNota from "screens/primaNota/Add/AddPrimaNota";

import BilancioDiVerifica from "screens/stampe/BilancioDiVerifica/BillancioDiVerificaList";
import RegistriIVA from "screens/stampe/RegistriIVA/RegistriIVA";
import LibroGiornale from "screens/stampe/LibroGiornale/LibroGiornale";

import { AuthContext } from "context/AuthContext";
import { Button, Stack } from "@mui/material";
import { useState } from "react";
import { UploadXML } from "screens/fatture/XMLFattureVendita/XMLUpload";
import { API } from "service/AxiosService";
import apiURL from "apiURL/apiURL";
import { resetToken } from "apiURL/commonFunctions";

const drawerWidth = 300;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function ClippedDrawer(props) {
  const [openLogout, setOpenLogout] = React.useState(false);
  const handleOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [path, setPath] = useState();
  const [selectedPath, setSelectedPath] = useState("");

  const [open, setOpen] = React.useState(false);
  const { currentUser, currentAccountSubject, dispatch } =
    useContext(AuthContext);

  const handleClick = () => {
    setOpen(!open);
  };

  const [openAnagrafiche, setOpenAnagrafiche] = React.useState(false);

  const handleClickAnagrafiche = () => {
    setOpenAnagrafiche(!openAnagrafiche);
  };

  const [openStampe, setOpenStampe] = React.useState(false);

  const handleClickStampe = () => {
    setOpenStampe(!openStampe);
  };

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const changeLanguage = (e) => {
    console.log("value ::: " + e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  const { i18n, t } = useTranslation();

  const doLogout = async () => {
    const data = await resetToken()
    if (data === true) {
      dispatch({ type: "LOGOUT", payload: null });
      localStorage.setItem("user", null);
        navigate('/')
        handleCloseLogout();
    }
  };

  useEffect(() => {
    //  console.log(`You changed the page to: ${location.pathname}`)
    setPath(location.pathname);
  }, [location, path]);

  const menuItemsStampe = [
    {
      label: t("mese"),
      path: "/liquidazione_iva_mese",
    },
    {
      label: t("anno"),
      path: "/liquidazione_iva_anno",
    },
    {
      label: t("mastroContabile"),
      path: "/mastroContabile",
    },
    {
      label: t("bilancioDiVerifica"),
      path: "/bilancioDiVerifica",
    },
    {
      label: t("registriIVA"),
      path: "/registriIVA",
    },
    {
      label: t("libroGiornale"),
      path: "/libroGiornale",
    },
  ];

  const menuItemsFatture = [
    // {
    //   label: t("importaXMLFattureVendita"),
    //   path: "/importaXMLFattureVendita_list",
    // },
    {
      label: t("fattureVendita"),
      path: "/fattureVendita_list",
    },
    {
      label: t("fattureAcquisto"),
      path: "/fattureAcquisto_list",
    },
    {
      label: t("fattureReverseCharge"),
      path: "/fattureReverseCharge_list",
    },
  ];
  const menuItemsAnagrafiche = [
    {
      label: t("clienti"),
      path: "/clienti_list",
    },
    {
      label: t("fornitori"),
      path: "/fornitori_list",
    },
  ];

  const handleItemClick = (path) => {
    setPath(path);
  };
  const isStampeActive = menuItemsStampe.some((item) => path === item.path);
  const isFattureActive = menuItemsFatture.some((item) => path === item.path);
  const isAnagraficheActive = menuItemsAnagrafiche.some(
    (item) => path === item.path
  );

  const scrollbarStyles = `
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: your_scrollbar_color;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: your_scrollbar_hover_color;
    }
  `;

  return (
    <Box
      sx={{
        display: "flex",

        // '&::-webkit-scrollbar': {
        //   width: '16px',
        //   borderRadius:"10px",

        // },
        // '&::-webkit-scrollbar-track': {
        //   background: "#fff",
        //   borderRadius:"8px",

        // },
        // '&::-webkit-scrollbar-thumb': {
        //   backgroundColor: '#4d96ff',
        //   borderRadius:"10px",
        //   border: "4px solid #ffffff",
        // },
        // '&::-webkit-scrollbar-thumb:hover': {
        //   background: '#2c70cf'
        // }
      }}
    >
      {/* <CssBaseline /> */}

      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={openLogout}
        onClose={handleCloseLogout}
      // onClose={props.handleCloseLogout}
      >
        <Box
          sx={{
            backgroundColor: "white",
            padding: 2,
            borderRadius: 2,
            width: {
              xs: "90%",
              sm: "90%",
              md: "70%",
              lg: "50%",
              xl: "40%",
            },
          }}
        >
          <Stack spacing={2}>
            <Typography
              variant="h5"
              sx={{
                color: "black",
              }}
            >
              {t("logoutTitle") + " " + "?"}
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={() => {
                  handleCloseLogout();
                }}
              >
                {t("annula")}
              </Button>
              {/* <Button onClick={() => props.onConfirm}>{t('conferma')}</Button> */}
              <Button
                onClick={() => {
                  doLogout();
                }}
              >
                {t("conferma")}
              </Button>
            </Box>
          </Stack>
        </Box>
      </Modal>

      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 16px",
          }}
        >
          <Typography variant="h5" component="div">
            {currentAccountSubject?.name
              ? currentAccountSubject?.name
              : "NESSUN SOGGETTO CONTABILE SELEZIONATO"}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <Link to="/login"> */}
            <IconButton
              sx={{
                color: "#fff",
                backgroundColor: "#BDBDBD",
                height: "40px",
                width: "40px",
                "&:hover": {
                  backgroundColor: "#bbbbbb",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "700",
                  lineHeight: "0px",
                }}
              >
                {/* {currentUser ? (
                  `${currentUser?.firstName?.charAt(
                    0
                  )}${currentUser?.lastName?.charAt(0)}`
                ) : (
                  <PersonIcon />
                )} */}
                {currentUser ? (
                `${currentUser?.firstName?.charAt(0) || ''}${currentUser?.lastName?.charAt(0) || ''}`
              ) : (
                <PersonIcon />
              )}
              </Typography>

              {/* <PersonIcon /> */}
            </IconButton>
            {/* </Link> */}

            {/* <Link to="/login"> */}
            <IconButton
              onClick={handleOpenLogout}
              sx={{
                color: "#fff",
                marginLeft: "8px",
                // marginRight: "24px"
              }}
            >
              <LogoutIcon
              // onClick={doLogout}
              />
            </IconButton>
            {/* </Link> */}

            {/* <FormControl sx={{ minWidth: 120, borderLeft:"1px #ffffff50 solid", paddingLeft:"16px"}}>
              { <Select }
              value={age}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
               // onChange={changeLanguage}
                onChange={handleChange}
                defaultValue="it"
                sx={{
                  border: "none",
                  color: "white",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  ".MuiSelect-icon": { color: "white" },
                }}
              >
                 <MenuItem value="" disabled>
            <em>{t('language')}</em>
          </MenuItem>
               <MenuItem value="it" selected>Italiano</MenuItem>
                    <MenuItem value="en">English</MenuItem>
              </Select>
            </FormControl>*/}
          </Box>
        </Box>
      </AppBar>

      <Drawer
        classes={{
          paper: scrollbarStyles,
        }}
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />

        <Box
          sx={{
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "0.4em",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#4d96ff",
              borderRadius: "10px",
            },
          }}
        >
          <Box
            component={"img"}
            sx={{
              width: {
                xs: 140,
                sm: 140,
                md: 140,
                lg: 250,
              },
            }}
            src={revisoftLogo}
          />
          <List>
            {/* account_subjects_list */}
            <Link to="/" style={{ textDecoration: "none", color: "#000000DE" }}>
              <ListItemButton selected={path === "/"}>
                <ListItemIcon>
                  <PeopleAltIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t("soggettiContabili")}
                  sx={{ textDecoration: "none" }}
                />
                {/* <Typography variant="h6">{t('soggettiContabili')}</Typography> */}
              </ListItemButton>
            </Link>

            {currentUser && currentUser.role === "Admin" && (
              <Link
                to="/user_list"
                style={{ textDecoration: "none", color: "#000000DE" }}
              >
                <ListItemButton selected={path === "/user_list"}>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={t("utenti")}
                    sx={{ textDecoration: "none" }}
                  />
                  {/* <Typography variant="h6">{t('soggettiContabili')}</Typography> */}
                </ListItemButton>
              </Link>
            )}

            {currentAccountSubject && (
              <>
                <ListItemButton
                  onClick={handleClickAnagrafiche}
                  selected={isAnagraficheActive}
                  sx={{
                    mb: 1,
                  }}
                >
                  <ListItemIcon>
                    <ContactPageIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("anagrafiche")}>
                    {/* <Typography variant="h6">{t('anagrafiche')}</Typography> */}
                  </ListItemText>

                  {openAnagrafiche ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openAnagrafiche} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {menuItemsAnagrafiche.map((item, index) => (
                      <Link
                        key={index}
                        to={item.path}
                        style={{ textDecoration: "none", color: "#000000DE" }}
                      >
                        <ListItemButton
                          sx={{ pl: 2 }}
                          selected={path === item.path}
                          onClick={() => handleItemClick(item.path)}
                        >
                          <ListItemIcon>
                            {/* <ArrowRightIcon /> */}
                          </ListItemIcon>
                          <ListItemText primary={item.label} />
                        </ListItemButton>
                      </Link>
                    ))}
                  </List>
                </Collapse>

                <Link
                  to="/esercizi_list"
                  style={{ textDecoration: "none", color: "#000000DE" }}
                >
                  <ListItemButton selected={path === "/esercizi_list"}>
                    <ListItemIcon>
                      <CalendarTodayIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("esercizi")}
                      sx={{ textDecoration: "none" }}
                    />
                    {/* <Typography variant="h6">{t('esercizi')}</Typography> */}
                  </ListItemButton>
                </Link>

                <Link
                  to="/piano_dei_conti_list"
                  style={{ textDecoration: "none", color: "#000000DE" }}
                >
                  <ListItemButton selected={path === "/piano_dei_conti_list"}>
                    <ListItemIcon>
                      <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("pianoDeiConti")} />
                    {/* <Typography variant="h6">{t('pianoDeiConti')}</Typography> */}
                  </ListItemButton>
                </Link>

                <Link
                  to="/tabelleIVA_list"
                  style={{ textDecoration: "none", color: "#000000DE" }}
                >
                  <ListItemButton selected={path === "/tabelleIVA_list"}>
                    <ListItemIcon>
                      <BackupTableIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("tabella") + " " + t("iva")}
                      sx={{ textDecoration: "none" }}
                    />
                    {/* <Typography variant="h6">{t('tabella')}</Typography> */}
                  </ListItemButton>
                </Link>

                <ListItemButton
                  onClick={handleClick}
                  selected={isFattureActive}
                  sx={{
                    mb: 1,
                  }}
                >
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("Fatture")}>
                    {/* <Typography variant="h6">{t('stampe')}</Typography> */}
                  </ListItemText>

                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <UploadXML />
                    {menuItemsFatture.map((item, index) => (
                      <Link
                        key={index}
                        to={item.path}
                        style={{ textDecoration: "none", color: "#000000DE" }}
                      >
                        <ListItemButton
                          sx={{ pl: 2 }}
                          selected={path === item.path}
                          onClick={() => handleItemClick(item.path)}
                        >
                          <ListItemIcon>
                            {/* <ArrowRightIcon /> */}
                          </ListItemIcon>
                          <ListItemText primary={item.label} />
                        </ListItemButton>
                      </Link>
                    ))}
                  </List>
                </Collapse>

                <Link
                  to="/primaNotaList_list"
                  style={{ textDecoration: "none", color: "#000000DE" }}
                >
                  <ListItemButton selected={path === "/primaNotaList_list"}>
                    <ListItemIcon>
                      <ReceiptLongIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t("primaNota")}
                      sx={{ textDecoration: "none" }}
                    />
                    {/* <Typography variant="h6">{t('primaNota')}</Typography> */}
                  </ListItemButton>
                </Link>

                <ListItemButton
                  onClick={handleClickStampe}
                  selected={isStampeActive}
                  sx={{
                    mb: 1,
                  }}
                >
                  <ListItemIcon>
                    <PrintIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("stampe")}>
                    {/* <Typography variant="h6">{t('stampe')}</Typography> */}
                  </ListItemText>

                  {openStampe ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openStampe} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {menuItemsStampe.map((item, index) => (
                      <Link
                        key={index}
                        to={item.path}
                        style={{ textDecoration: "none", color: "#000000DE" }}
                      >
                        <ListItemButton
                          sx={{ pl: 2 }}
                          selected={path === item.path}
                          onClick={() => handleItemClick(item.path)}
                        >
                          <ListItemIcon>
                            {/* <ArrowRightIcon /> */}
                          </ListItemIcon>
                          <ListItemText primary={item.label} />
                        </ListItemButton>
                      </Link>
                    ))}
                  </List>
                </Collapse>

                <ListItemButton>
                  <ListItemIcon>
                    <ReceiptIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("manuale")} />
                  {/* <Typography variant="h6">{t('manuale')}</Typography> */}
                </ListItemButton>
              </>
            )}
          </List>

          {/* <Divider />

          <List>
            {["All mail", "Trash", "Spam"].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List> */}
        </Box>
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {/* <Toolbar /> */}
        <Routes>
          {/* <Route path="/" name="Dashboard" element={<Dashboard />} /> */}
          <Route path="/user_list" name="UserList" element={<UserList />} />
          <Route
            path="/"
            name="AccountSubjectsList"
            element={<AccountSubjectsList />}
          />
          <Route
            path="/piano_dei_conti_list"
            name="PianoDeiCountiList"
            element={<PianoDeiCountiList />}
          />
          <Route
            path="/clienti_list"
            name="ClientiList"
            element={<ClientiList />}
          />
          <Route
            path="/fornitori_list"
            name="FornitoriList"
            element={<FornitoriList />}
          />
          <Route
            path="/tabelleIVA_list"
            name="TabelleIVAList"
            element={<TabelleIVAList />}
          />
          <Route
            path="/fattureVendita_list"
            name="FattureVenditalist"
            element={<FattureVenditaList />}
          />
          <Route
            path="/fattureReverseCharge_list"
            name="FattureReverseChargelist"
            element={<FattureReverseChargeList />}
          />
          <Route
            path="/fattureAcquisto_list"
            name="FattureAcquistolist"
            element={<FattureAcquistoList />}
          />
          <Route
            path="/add_fatture_vendita"
            name="AddFattureVendita"
            element={<AddFattureVendita />}
          />
          <Route
            path="/add_fatture_acquisto"
            name="AddFattureAcquisto"
            element={<AddFattureAcquisto />}
          />
          <Route
            path="/add_fatture_reverse_charge"
            name="AddFattureReverseCharge"
            element={<AddFattureReverseCharge />}
          />
          <Route
            path="/registra_prima_nota"
            name="RegistraPrimaNota"
            element={<RegistraPrimaNota />}
          />
          <Route
            path="/esercizi_list"
            name="EserciziList"
            element={<EserciziList />}
          />
          <Route path="/liquidazione_iva_mese" name="Mese" element={<Mese />} />
          <Route path="/liquidazione_iva_anno" name="Anno" element={<Anno />} />
          <Route
            path="/primaNotaList_list"
            name="PrimaNotalist"
            element={<PrimaNotaList />}
          />
          <Route
            path="/mastroContabile"
            name="MastroContabile"
            element={<MastroContabile />}
          />
          <Route
            path="/bilancioDiVerifica"
            name="BilancioDiVerifica"
            element={<BilancioDiVerifica />}
          />
          <Route
            path="/registriIVA"
            name="RegistriIVA"
            element={<RegistriIVA />}
          />
          <Route
            path="/libroGiornale"
            name="LibroGiornale"
            element={<LibroGiornale />}
          />
          LibroGiornale
        </Routes>
      </Box>
    </Box>
  );
}
